import { observable, action, decorate, ObservableMap } from 'mobx'

class CommentsStore {
  firestore = null
  commentsCache = new ObservableMap()

  setFirestore(firestore) {
    this.firestore = firestore
  }

  async fetchComments(index) {
    try {
      const { docs } = await this.firestore
        .collection('comments')
        .where('postId', '==', index)
        .where('status', '==', 'approved')
        .orderBy('createdAt')
        .limit(100)
        .get()
      const comments = docs.map(doc => {
        const data = doc.data()
        const replies = (data.replies || [])
          .map(reply => JSON.parse(reply))
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))

        return { ...data, id: doc.id, replies }
      })

      this.commentsCache.set(index, comments)
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  async addComment(index, comment) {
    try {
      const ref = await this.firestore.collection('comments').add({
        ...comment,
        postId: index,
        status: 'approved',
        createdAt: new Date().valueOf(),
        replies: [],
      })
      const doc = await ref.get()
      const comments = this.commentsCache.has(index)
        ? this.commentsCache.get(index)
        : []

      comments.push({ id: doc.id, ...doc.data() })
      this.commentsCache.set(index, comments)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  dehydrate() {
    return {
      firestore: this.firestore,
      commentsCache: this.commentsCache,
    }
  }
}

decorate(CommentsStore, {
  firestore: observable,
  commentsCache: observable,
  setFirestore: action,
  fetchComments: action,
  addComment: action,
})

export default CommentsStore
