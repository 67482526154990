import React from 'react'
import { Provider } from 'mobx-react'
// import GlobalStore from './src/stores/global-store'
import CommentsStore from './src/stores/comments-store'

export default ({ element }) => (
  <Provider
    // global={new GlobalStore()}
    comments={new CommentsStore()}
  >
    {element}
  </Provider>
)
