module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"firestore":true}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150446674-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dw2-gatsby","short_name":"dw2","start_url":"/","background_color":"#FFFFFF","theme_color":"#EB3461","display":"minimal-ui","icon":"src/images/dw2-icon.png"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
