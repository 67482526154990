// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-article-js": () => import("/opt/build/repo/src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-chop-lines-js": () => import("/opt/build/repo/src/pages/projects/chop-lines.js" /* webpackChunkName: "component---src-pages-projects-chop-lines-js" */),
  "component---src-pages-projects-evilneon-js": () => import("/opt/build/repo/src/pages/projects/evilneon.js" /* webpackChunkName: "component---src-pages-projects-evilneon-js" */),
  "component---src-pages-projects-polysize-js": () => import("/opt/build/repo/src/pages/projects/polysize.js" /* webpackChunkName: "component---src-pages-projects-polysize-js" */),
  "component---src-pages-projects-styled-tidy-js": () => import("/opt/build/repo/src/pages/projects/styled-tidy.js" /* webpackChunkName: "component---src-pages-projects-styled-tidy-js" */),
  "component---src-pages-projects-weedmaps-js": () => import("/opt/build/repo/src/pages/projects/weedmaps.js" /* webpackChunkName: "component---src-pages-projects-weedmaps-js" */)
}

